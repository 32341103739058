<template>
  <v-container fluid>
    <v-row
      dense
      align="center"
      class="mb-4"
    >
      <v-col cols="mr-auto">
        <!-- <v-btn
          elevation="1"
          color="primary"
          dark
          @click="setNewFilters({name: '', isActive: '',salesCode: ''})"
        >
          {{$_strings.masterSales.REFRESH}}
        </v-btn> -->
      </v-col>
      <v-col cols="auto">
        <FilterList
          @setNewFilters="setNewFilters"
        />
      </v-col>
      <v-col cols="auto">
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          v-model="search"
          @keyup.enter="setNewFilters({ name: search })"
          :placeholder="$_strings.masterSales.SEARCH_TEXT"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setNewFilters({ name: search })">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>

    </v-row>
    <List
      :key="key_list"
      :filters="filters"
    />
  </v-container>
</template>

<script>
import List from './List.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'sales',
  components: {
    List,
    FilterList,
  },
  data() {
    return {
      key_list: 0,
      search: this.$route.query.name || '',
      filters: {
        salesCode: this.$route.query.salesCode || '',
        isActive: this.$route.query.isActive || '',
        name: this.$route.query.name || '',
      },
    };
  },
  methods: {
    fetchList() {
      this.key_list += 1;
    },
    setNewFilters(newFilters) {
      const newQuery = {
        ...this.$route.query,
        ...newFilters,
        page: 1,
      };
      if (JSON.stringify(newQuery).replace(/["]/g, '') !== JSON.stringify(this.$route.query).replace(/["]/g, '')) {
        this.$router.replace({
          query: newQuery,
        });
        this.filters = {
          ...this.filters,
          ...newFilters,
        };
      }
      this.fetchList();
    },
  },
};
</script>
