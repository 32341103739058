<template>
  <v-menu
    :close-on-content-click="closeOnContentClick"
    bottom
    width="300"
    offset-y
    z-index="1"
    allow-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        hide-details
        height="10"
        class="caption"
        prepend-inner-icon="mdi-filter"
        placeholder="Filter Berdasarkan"
        outlined
        dense
        readonly
        v-bind="attrs"
        @click="closeOnContentClick = false;"
        v-on="on"
      ></v-text-field>
    </template>
    <v-list
      width="300"
      min-height="150"
      max-height="400"
      class="overflow-auto"
    >
      <section class="ml-4">
        <div v-for="item in listItems" :key="item.key">
          <span class="caption font-weight-bold">{{item.title}}</span>
          <v-autocomplete
            :loading="isLoadingListSales"
            :items="items[item.items]"
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="filters[item.vModel]"
            class="caption mb-4 mr-4"
            dense
            hide-details
            flat
          />
        </div>
      </section>
      <section class="pl-2 pr-2">
        <v-btn
          depressed
          color="primary"
          block
          @click="setFilter"
        >
          {{$_strings.common.APPLY}}
        </v-btn>
        <v-btn
          depressed
          class="mt-2 white--text"
          color="red"
          block
          @click="closeOnContentClick = true"
        >
          {{$_strings.common.CLOSE}}
        </v-btn>
      </section>
    </v-list>
  </v-menu>
</template>

<script>
import { filterDuplicateValues } from '@/helper/commonHelpers';

export default {
  name: 'fiter-oracle-cost',
  mounted() {
    this.updateListSales();
  },
  data() {
    return {
      closeOnContentClick: false,
      isLoadingListSales: false,
      filters: {
        isActive: this.$route.query.isActive || '',
        salesCode: this.$route.query.salesCode || '',
      },
      filterListSales: {
        page: 0,
        size: 25,
        search: '',
      },
      items: {
        status: [
          {
            text: 'Semua Status',
            value: '',
          },
          {
            text: 'Aktif',
            value: 'true',
          },
          {
            text: 'Tidak Aktif',
            value: 'false',
          },
        ],
        salesCodeList: [
          {
            text: 'Semua kode Status',
            value: '',
          },
        ],
      },
      listItems: [
        {
          key: 1,
          title: 'Kode Sales',
          vModel: 'salesCode',
          items: 'salesCodeList',
        },
        {
          key: 2,
          title: 'Status',
          vModel: 'isActive',
          items: 'status',
        },
      ],
    };
  },
  methods: {
    filterDuplicateValues,
    setFilter() {
      this.closeOnContentClick = true;
      this.$emit('setNewFilters', this.filters);
    },
    async updateListSales() {
      try {
        this.isLoadingListSales = true;
        const filter = {
          page: 0,
          size: 150,
        };
        const result = await this.$_services.masterSales.getListMasterSales(filter);
        const _items = result.data.contents.map((item) => ({
          text: item.salesCode,
          value: item.salesCode,
        }));
        this.items.salesCodeList = this.filterDuplicateValues([...this.items.salesCodeList, ..._items]);
      } finally {
        this.isLoadingListSales = false;
      }
    },
  },
};
</script>
