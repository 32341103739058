<template>
  <v-data-table
    item-key="id"
    :loading="isLoading"
    :headers="displayedHeader"
    :items="masterSalesItems"
    :server-items-length="totalItems"
    :options.sync="pagination"
    :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
  >
    <template v-slot:[`item.salesCode`]="{ item }">
      <span>
        {{item.salesCode}}
      </span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <span>{{item.name}}</span>
    </template>
    <template v-slot:[`item.isActive`]="{ item }">
      <span>{{item.isActive ? 'Aktif' : ' Tidak Aktif'}}</span>
    </template>
  </v-data-table>
</template>

<script>

import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';

export default {
  name: 'list-sales',
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.masterSales.CODE,
          value: 'salesCode',
        },
        {
          text: this.$_strings.masterSales.NAME,
          value: 'name',
        },
        {
          text: this.$_strings.masterSales.STATUS,
          value: 'isActive',
        },
      ],
      masterSalesItems: [],
      pagination: this.defaultPagination(),
      totalItems: 0,
      itemStatus: [
        {
          key: 'Aktif',
          value: true,
        },
        {
          key: 'Tidak Aktif',
          value: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        this.handlerPagination(this, newVal);
        this.fetchMasterSalesList();
      },
      deep: true,
    },
  },
  methods: {
    defaultPagination,
    handlerPagination,
    handleSortBy,
    skipEmptyStringObject,
    dateFormat,
    toggleLoading(boolean) {
      if (this.masterSalesItems.length > 0) {
        this.isLoading = false;
        return boolean ? this.$root.$loading.show() : this.$root.$loading.hide();
      }
      this.$root.$loading.hide();
      this.isLoading = boolean;
    },
    async fetchMasterSalesList() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = skipEmptyStringObject({
        // type: 'qIn(COST)',
        page: page - 1,
        size: itemsPerPage,
        ...this.filters,
      });
      if (this.filters.name) filters.name = `qLike(${filters.name})`;
      if (this.filters.salesCode) filters.salesCode = `qLike(${filters.salesCode})`;
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0].includes('reason') ? 'name' : sortBy[0]},${isDesc}`;
      }
      try {
        this.toggleLoading(true);
        const result = await this.$_services.masterSales.getListMasterSales(this.skipEmptyStringObject(filters));
        this.masterSalesItems = result.data.contents;
        this.totalItems = result.data.totalData;
      } finally {
        this.toggleLoading(false);
      }
    },
  },
};
</script>
